// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "../helpers/URL.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Event_ from "../model/Event_.bs.js";
import * as Account from "../model/Account.bs.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Trustee from "../model/Trustee.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Invitation from "../model/Invitation.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";
import * as ReactI18next from "react-i18next";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as AsyncStorage from "@react-native-async-storage/async-storage";

function electionsUpdate(elections, ballots, ev) {
  var match = ev.type_;
  if (match === "election.init") {
    var election = Event_.ElectionInit.parse(ev);
    var election_electionId = ev.cid;
    var election_adminIds = election.adminIds;
    var election_voterIds = election.voterIds;
    var election_params = election.params;
    var election_trustees = election.trustees;
    var election_pda = election.pda;
    var election_pdb = election.pdb;
    var election_result = election.result;
    var election$1 = {
      electionId: election_electionId,
      adminIds: election_adminIds,
      voterIds: election_voterIds,
      params: election_params,
      trustees: election_trustees,
      pda: election_pda,
      pdb: election_pdb,
      result: election_result
    };
    var elections$1 = Belt_MapString.set(elections, ev.cid, election$1);
    return [
            elections$1,
            ballots
          ];
  }
  if (match === "election.ballot") {
    var ballot = Event_.ElectionBallot.parse(ev);
    return [
            elections,
            Belt_Array.concat(ballots, [ballot])
          ];
  }
  if (match === "election.voter") {
    var match$1 = JSON.parse(ev.content);
    var electionId = match$1.electionId;
    var election$2 = Belt_MapString.get(elections, electionId);
    if (election$2 !== undefined) {
      var election_electionId$1 = election$2.electionId;
      var election_adminIds$1 = election$2.adminIds;
      var election_voterIds$1 = Belt_Array.concat(election$2.voterIds, [match$1.voterId]);
      var election_params$1 = election$2.params;
      var election_trustees$1 = election$2.trustees;
      var election_pda$1 = election$2.pda;
      var election_pdb$1 = election$2.pdb;
      var election_result$1 = election$2.result;
      var election$3 = {
        electionId: election_electionId$1,
        adminIds: election_adminIds$1,
        voterIds: election_voterIds$1,
        params: election_params$1,
        trustees: election_trustees$1,
        pda: election_pda$1,
        pdb: election_pdb$1,
        result: election_result$1
      };
      var elections$2 = Belt_MapString.set(elections, electionId, election$3);
      return [
              elections$2,
              ballots
            ];
    }
    console.log([
          "Cannot find election",
          electionId
        ]);
    return [
            elections,
            ballots
          ];
  }
  if (match === "election.delegation") {
    var match$2 = JSON.parse(ev.content);
    var delegateId = match$2.delegateId;
    var voterId = match$2.voterId;
    var electionId$1 = match$2.electionId;
    var election$4 = Belt_MapString.get(elections, electionId$1);
    if (election$4 !== undefined) {
      var voterIds = Belt_Array.map(election$4.voterIds, (function (userId) {
              if (userId === voterId) {
                return delegateId;
              } else {
                return userId;
              }
            }));
      console.log([
            election$4.voterIds,
            voterId,
            voterIds
          ]);
      var election_electionId$2 = election$4.electionId;
      var election_adminIds$2 = election$4.adminIds;
      var election_params$2 = election$4.params;
      var election_trustees$2 = election$4.trustees;
      var election_pda$2 = election$4.pda;
      var election_pdb$2 = election$4.pdb;
      var election_result$2 = election$4.result;
      var election$5 = {
        electionId: election_electionId$2,
        adminIds: election_adminIds$2,
        voterIds: voterIds,
        params: election_params$2,
        trustees: election_trustees$2,
        pda: election_pda$2,
        pdb: election_pdb$2,
        result: election_result$2
      };
      var elections$3 = Belt_MapString.set(elections, electionId$1, election$5);
      return [
              elections$3,
              ballots
            ];
    }
    console.log([
          "Cannot find election",
          electionId$1
        ]);
    return [
            elections,
            ballots
          ];
  }
  var match$3 = JSON.parse(ev.content);
  var electionId$2 = match$3.electionId;
  var election$6 = Belt_MapString.get(elections, electionId$2);
  if (election$6 !== undefined) {
    var election_electionId$3 = election$6.electionId;
    var election_adminIds$3 = election$6.adminIds;
    var election_voterIds$2 = election$6.voterIds;
    var election_params$3 = election$6.params;
    var election_trustees$3 = election$6.trustees;
    var election_pda$3 = match$3.pda;
    var election_pdb$3 = match$3.pdb;
    var election_result$3 = match$3.result;
    var election$7 = {
      electionId: election_electionId$3,
      adminIds: election_adminIds$3,
      voterIds: election_voterIds$2,
      params: election_params$3,
      trustees: election_trustees$3,
      pda: election_pda$3,
      pdb: election_pdb$3,
      result: election_result$3
    };
    var elections$4 = Belt_MapString.set(elections, electionId$2, election$7);
    return [
            elections$4,
            ballots
          ];
  }
  console.log([
        "Cannot find election",
        electionId$2
      ]);
  return [
          elections,
          ballots
        ];
}

function storeAccounts(ids, _dispatch) {
  Account.store_all(ids);
}

function storeEvents(evs, _dispatch) {
  Event_.store_all(evs);
}

function storeTrustees(trustees, _dispatch) {
  Trustee.store_all(trustees);
}

function storeInvitations(invitations, _dispatch) {
  Invitation.store_all(invitations);
}

function storeLanguage(language, _dispatch) {
  AsyncStorage.default.setItem("config.language", language);
}

function loadAccounts(dispatch) {
  Account.loadAll(undefined).then(function (accounts) {
        return Belt_Array.map(accounts, (function (account) {
                      return Curry._1(dispatch, {
                                  TAG: /* Account_Add */1,
                                  _0: account
                                });
                    }));
      });
}

function loadEvents(dispatch) {
  Event_.loadAll(undefined).then(function (evs) {
        return Belt_Array.map(evs, (function (ev) {
                      return Curry._1(dispatch, {
                                  TAG: /* Event_Add */2,
                                  _0: ev
                                });
                    }));
      });
}

function loadTrustees(dispatch) {
  Trustee.loadAll(undefined).then(function (os) {
        return Belt_Array.map(os, (function (o) {
                      return Curry._1(dispatch, {
                                  TAG: /* Trustee_Add */4,
                                  _0: o
                                });
                    }));
      });
}

function loadInvitations(dispatch) {
  Invitation.loadAll(undefined).then(function (os) {
        return Belt_Array.map(os, (function (o) {
                      return Curry._1(dispatch, {
                                  TAG: /* Invitation_Add */5,
                                  _0: o
                                });
                    }));
      });
}

function loadLanguage(param, _dispatch) {
  AsyncStorage.default.getItem("config.language").then(function (prim) {
          if (prim === null) {
            return ;
          } else {
            return Caml_option.some(prim);
          }
        }).then(function (language) {
        if (language === undefined) {
          return ;
        }
        var match = ReactI18next.useTranslation();
        match.i18n.changeLanguage(language);
      });
}

function clearAccounts(_dispatch) {
  Account.clear(undefined);
}

function clearEvents(_dispatch) {
  Event_.clear(undefined);
}

function clearTrustees(_dispatch) {
  Trustee.clear(undefined);
}

function loadAndFetchEvents(dispatch) {
  Event_.loadAll(undefined).then(function (evs) {
          Belt_Array.forEach(evs, (function (ev) {
                  Curry._1(dispatch, {
                        TAG: /* Event_Add */2,
                        _0: ev
                      });
                }));
          return evs;
        }).then(function (evs) {
        console.log(evs);
        var latestId = Belt_Array.reduce(evs, 0, (function (acc, ev) {
                if (acc > ev.id) {
                  return acc;
                } else {
                  return ev.id;
                }
              }));
        return fetch("" + $$URL.bbs_url + "/events?from=" + String(latestId) + "").then(function (prim) {
                      return prim.json();
                    }).then(function (response) {
                    var jsons = Js_json.decodeArray(response);
                    if (jsons !== undefined) {
                      Belt_Array.forEach(jsons, (function (json) {
                              try {
                                return Curry._1(dispatch, {
                                            TAG: /* Event_Add */2,
                                            _0: Event_.from_json(json)
                                          });
                              }
                              catch (exn){
                                console.log("Cannot parse event");
                                return ;
                              }
                            }));
                      return Curry._1(dispatch, /* Fetched */4);
                    }
                    
                  });
      });
}

function fetchLatestEvents(latestId, dispatch) {
  fetch("" + $$URL.bbs_url + "/events?from=" + String(latestId) + "").then(function (prim) {
          return prim.json();
        }).then(function (response) {
        var jsons = Js_json.decodeArray(response);
        if (jsons !== undefined) {
          Belt_Array.forEach(jsons, (function (json) {
                  try {
                    return Curry._1(dispatch, {
                                TAG: /* Event_Add */2,
                                _0: Event_.from_json(json)
                              });
                  }
                  catch (exn){
                    console.log("Cannot parse event");
                    return ;
                  }
                }));
          return Curry._1(dispatch, /* Fetched */4);
        }
        
      });
}

function broadcastEvent(ev, _dispatch) {
  Event_.broadcast(ev);
}

function goToUrl(dispatch) {
  if (ReactNative.Platform.OS !== "web") {
    return ;
  }
  var url = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
  Curry._1(dispatch, {
        TAG: /* Navigate */0,
        _0: url.path
      });
}

export {
  electionsUpdate ,
  storeAccounts ,
  storeEvents ,
  storeTrustees ,
  storeInvitations ,
  storeLanguage ,
  loadAccounts ,
  loadEvents ,
  loadTrustees ,
  loadInvitations ,
  loadLanguage ,
  clearAccounts ,
  clearEvents ,
  clearTrustees ,
  loadAndFetchEvents ,
  fetchLatestEvents ,
  broadcastEvent ,
  goToUrl ,
}
/* URL Not a pure module */
