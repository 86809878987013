// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Account from "../model/Account.bs.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ElectionHeader from "./ElectionHeader.bs.js";

function ElectionToken(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var secret = Props.secret;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var account = Account.make2(secret);
  console.log("Adding account");
  console.log(account);
  Curry._1(dispatch, {
        TAG: /* Account_Add */1,
        _0: account
      });
  Curry._1(dispatch, {
        TAG: /* Navigate */0,
        _0: {
          hd: "elections",
          tl: {
            hd: electionId,
            tl: {
              hd: "booth",
              tl: /* [] */0
            }
          }
        }
      });
  return React.createElement(React.Fragment, undefined, React.createElement(ElectionHeader.make, {
                  election: election
                }), React.createElement(S.Title.make, {
                  children: "Invitation par token. Please wait redirect."
                }));
}

var make = ElectionToken;

export {
  make ,
}
/* S Not a pure module */
