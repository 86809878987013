// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Core from "../Core.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Account from "../model/Account.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Election from "../model/Election.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactNative from "react-native";
import * as ReactI18next from "react-i18next";
import * as ElectionHeader from "./ElectionHeader.bs.js";
import * as ReactNativePaper from "react-native-paper";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

function ElectionBooth$Choice(Props) {
  var name = Props.name;
  var selected = Props.selected;
  var onSelect = Props.onSelect;
  var iconName = selected ? "radiobox-marked" : "radiobox-blank";
  return React.createElement(ReactNativePaper.List.Item, {
              onPress: (function (param) {
                  Curry._1(onSelect, undefined);
                }),
              title: name,
              style: {
                padding: 20.0,
                paddingLeft: 40.0
              },
              left: (function (param) {
                  return React.createElement(ReactNativePaper.List.Icon, {
                              icon: iconName
                            });
                })
            });
}

var Choice = {
  make: ElectionBooth$Choice
};

function ElectionBooth$BoothAfterVote(Props) {
  var electionId = Props.electionId;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  return React.createElement(React.Fragment, undefined, React.createElement(ReactNativePaper.Text, {
                  style: S.flatten([
                        S.title,
                        {
                          margin: 30.0
                        }
                      ]),
                  children: "Merci pour votre vote"
                }), React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Navigate */0,
                            _0: {
                              hd: "elections",
                              tl: {
                                hd: electionId,
                                tl: /* [] */0
                              }
                            }
                          });
                    }),
                  title: "Retour à l'élection"
                }));
}

var BoothAfterVote = {
  make: ElectionBooth$BoothAfterVote
};

function ElectionBooth$Booth(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var account = Props.account;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = ReactI18next.useTranslation();
  var match$2 = React.useState(function () {
        
      });
  var setChoice = match$2[1];
  var choice = match$2[0];
  var question = Election.description(election);
  var question$1 = question === "" ? match$1.t("election.new.question") : question;
  return React.createElement(React.Fragment, undefined, React.createElement(ReactNative.View, {
                  style: S.questionBox,
                  children: null
                }, React.createElement(S.Section.make, {
                      title: question$1
                    }), Belt_Array.mapWithIndex(Election.choices(election), (function (i, choiceName) {
                        var selected = Caml_obj.equal(choice, i);
                        return React.createElement(ElectionBooth$Choice, {
                                    name: choiceName,
                                    selected: selected,
                                    onSelect: (function (param) {
                                        Curry._1(setChoice, (function (param) {
                                                return i;
                                              }));
                                      }),
                                    key: String(i)
                                  });
                      }))), React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      var nbChoices = Election.choices(election).length;
                      Core.Ballot.vote(electionId, account, choice, nbChoices, state, dispatch);
                    }),
                  title: "Voter"
                }));
}

var Booth = {
  make: ElectionBooth$Booth
};

function getSecret(param) {
  if (ReactNative.Platform.OS !== "web") {
    return ;
  }
  var url = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
  if (url.hash.length > 12) {
    return url.hash;
  }
  
}

function ElectionBooth(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var match = StateContext.use(undefined);
  var oSecret = getSecret(undefined);
  var tmp;
  if (oSecret !== undefined) {
    var account = Account.make2(oSecret);
    var oBallot = Belt_Array.getBy(match[0].ballots, (function (ballot) {
            if (ballot.electionId === electionId) {
              return ballot.voterId === account.userId;
            } else {
              return false;
            }
          }));
    tmp = oBallot !== undefined ? React.createElement(ElectionBooth$BoothAfterVote, {
            electionId: electionId
          }) : React.createElement(ElectionBooth$Booth, {
            election: election,
            electionId: electionId,
            account: account
          });
  } else {
    tmp = React.createElement(ReactNativePaper.Text, {
          style: S.flatten([
                S.title,
                {
                  margin: 30.0
                }
              ]),
          children: "Vous n'avez pas de clés de vote"
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(ElectionHeader.make, {
                  election: election
                }), tmp);
}

var make = ElectionBooth;

export {
  Choice ,
  BoothAfterVote ,
  Booth ,
  getSecret ,
  make ,
}
/* S Not a pure module */
